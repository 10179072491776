/* eslint-disable no-console */

import React, { useState } from "react"
import { Box, Grid, Tabs, Tab, createTheme, ThemeProvider } from "@mui/material";
import Modal from 'react-modal';
import { toast, ToastContainer } from "react-toastify";
import { pl } from "date-fns/locale";
import DatePicker, { registerLocale } from "react-datepicker";

import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

import { useEffectOnce } from "./useEffectOnce";
import "./App.css";
import BigWindowTable from "./BigWindowTable";
import SmallWindowTable from "./SmallWindowTable";
import Footer from "./Footer";
import BigTextField from "./BigTextField";
import SmallTextField from "./SmallTextField";


const API_URL = "https://houseofseitan.com.pl"
// const API_URL = "http://localhost:8000"
const RFC_EMAIL_REGEX = RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);


function App() {
    // mode
    // casual or event
    const [mode, setMode] = React.useState("event");
    const handleMode = async (event: React.SyntheticEvent, newValue: string) => {
        setMode(newValue);
        await getDishes(newValue);
    };

    const [dishes, setDishes] = useState([])
    const [sum, setSum] = useState(0)

    // items
    const [countArray, setCountArray] = useState(new Array(100).fill(0));
    const [dishArray, setDishArray] = useState(new Array(100).fill(0));

    // comment
    const [comment, setComment] = useState("");
    const handleComment = (event: React.FocusEvent<HTMLInputElement>) => {
        setComment(event.target.value);
    };

    // customer data
    const [firstName, setFirstName] = useState("");
    const handleFirstName = (event: React.FocusEvent<HTMLInputElement>) => {
        setFirstName(event.target.value);
    };
    const [lastName, setLastName] = useState("");
    const handleLastName = (event: React.FocusEvent<HTMLInputElement>) => {
        setLastName(event.target.value);
    };
    const [phone, setPhone] = useState("");
    const handlePhone = (event: React.FocusEvent<HTMLInputElement>) => {
        setPhone(event.target.value);
    };
    const [email, setEmail] = useState("");
    const handleEmail = (event: React.FocusEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    // collect date
    const [collectDate, setCollectDate] = React.useState(new Date());
    registerLocale("pl", pl)
    const handleCollectDate = (event: React.FocusEvent<HTMLInputElement>) => {
        setCollectDate(new Date(event.target.value));
    };

    // location
    const [location, setLocation] = React.useState("WAJ");

    // consents
    const [consent1, setConsent1] = React.useState(false);
    const [consent2, setConsent2] = React.useState(false);
    const [consent3, setConsent3] = React.useState(false);

    const [disabled, setDisabled] = useState(false);

    useEffectOnce(() => {
        const fetchData = async () => {
            await getDishes(mode);
        }

        fetchData().catch(console.error);
    });

    function handleIncrementClick(index: number, step: number, dish_id: number) {
        const nextCounters = countArray.map((c, i) => {
            if (i === index) {
                if (!(step < 0 && c === 0)) {
                    return c + step;
                }
                return c;
            } else {
                return c;
            }
        });
        setCountArray(nextCounters);
        handleDishArray(index, dish_id);
    }

    function handleDishArray(index: number, dish_id: number) {
        const nextCounters = dishArray.map((c, i) => {
            if (i === index) {
                return dish_id;
            } else {
                return c;
            }
        });
        setDishArray(nextCounters);
    }

    // const handleLocation = (event: Event) => {
    //     if (event.target != null) {
    //         setLocation(event.target.value)
    //     }
    //     else {
    //         setLocation("")
    //     }
    // }

    const handleConsent1 = () => {
        setConsent1(!consent1);
    };

    const handleConsent2 = () => {
        setConsent2(!consent2);
    };

    const handleConsent3 = () => {
        setConsent3(!consent3);
    };

    const handleDisabled = () => {
        setDisabled(true);
        setTimeout(() => {
            setDisabled(false);
        }, 10000);
    };

    function calculateSum() {
        let calculatedSum = 0;
        for (let i = 0; i < dishes.length; i++) {
            calculatedSum += dishes[i]["price"] * countArray[i];
        }
        setSum(calculatedSum);
    }

    const handleClear = () => {
        setSum(0);
        setCountArray(new Array(100).fill(0));
        setDishArray(new Array(100).fill(0));
        setComment("");
        setFirstName("");
        setLastName("");
        setPhone("");
        setEmail("");
        setCollectDate(new Date());
        setLocation("WAJ");
        setConsent1(false);
        setConsent2(false);
        setConsent3(false);
    };

    const getDishes = async (selected_mode: string) => {
        try {
            const response = await fetch(API_URL + "/api/dishes/?mode=" + selected_mode);
            if (!response.ok) {
                throw Error(response.statusText);
            }
            const json = await response.json();
            setDishes(json);
        } catch (error: any) {
            console.error(error.message);
        }
    }

    const notify = (text: string) => toast(text);

    const validate_data = () : boolean => {
        // data validation
        let dishesSum = 0;
        for (let i = 0; i < dishes.length; i++) {
            dishesSum += countArray[i];
        }
        if (dishesSum === 0) {
            notify("Zamów coś");
            return false;
        }
        if (firstName === "") {
            notify("Uzupełnij imię");
            return false;
        }
        if (lastName === "") {
            notify("Uzupełnij nazwisko");
            return false;
        }
        if (phone === "") {
            notify("Uzupełnij telefon");
            return false;
        }
        if (email === "") {
            notify("Uzupełnij maila");
            return false;
        }
        if (!email.match(RFC_EMAIL_REGEX)) {
            notify("Nieprawidłowy mail");
            return false;
        }

        if (mode == "casual") {
            // od pon do sb - 1-6, ndz - 0
            const today = new Date();
            const weekDay = today.getDay();
            // todo: move to variables file
            const breakTime = today.getHours() >= 18 ? 1 : 0
            let first, last;
            if ((weekDay >= 0 && weekDay <= 4) || (weekDay == 5 && !breakTime) ) {
                // between sunday 00:00 and friday 17:59
                first = new Date(today.getTime() + (1000 * 60 * 60 * 24) * (breakTime + 1));
                last = new Date(today.getTime() + (1000 * 60 * 60 * 24) * (7 - weekDay))
            }
            else {
                // between friday 18:00 and saturday 23:59
                first = new Date(today.getTime() + (1000 * 60 * 60 * 24) * (7 - weekDay + 1));
                last = new Date(first.getTime() + (1000 * 60 * 60 * 24) * 6)
            }
            collectDate.setHours(1, 0, 0, 0)
            first.setHours(1, 0, 0, 0)
            last.setHours(1, 0, 0, 0)

            if (collectDate < first || collectDate > last) {
                notify("Błędna data. Wybierz datę z przedziału: " + first.toISOString().split('T')[0] + "  -  " + last.toISOString().split('T')[0]);
                return false;
            }
        }
        else {
            setCollectDate(new Date("2024-03-30T13:00:00.000Z"))
        }

        if (!(location == "WAJ" || location == "BRZ")) {
            notify("Błędna lokalizacja odbioru");
            return false;
        }
        if (!consent1 || !consent2) {
            notify("Brakująca zgoda");
            return false;
        }
        return true;
    }

    const createOrder = async (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        if (disabled) {
            return;
        }
        handleDisabled();

        const items = [];
        for (let i = 0; i < dishes.length; i++) {
            if (dishArray[i] !== 0 && countArray[i] !== 0) {
                items.push({dish_id: dishArray[i], amount: countArray[i]});
            }
        }
        const consents = [{consent: consent1}, {consent: consent2}, {consent: consent3}];
        const data = {
            customer: {
                first_name: firstName, last_name: lastName, phone: phone, email: email
            },
            comment: comment,
            items: items,
            consents: consents,
            location: location,
            collect_date: collectDate.toISOString().split('T')[0],
            mode: mode
        };

        const url = API_URL + "/api/orders/"
        try {
            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (!response.ok) {
                notify("Ups, coś poszło nie tak. Spróbuj ponownie złożyć zamówienie");
                throw Error(response.statusText);
            }
            handleClear();
            setIsOpen(false);
            const json = await response.json();
            notify("Zamówienie zostało złożone");
        } catch (error: any) {
            notify("Ups, coś poszło nie tak. Spróbuj ponownie złożyć zamówienie");
            console.error(error.message);
        }
    }

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        if (!validate_data()) {
            return;
        }
        calculateSum();
        setIsOpen(true);
    }

    function afterOpenModal() {
        console.log("abc");
    }

    function closeModal() {
        setIsOpen(false);
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    const theme = createTheme({
        typography: {
            fontFamily: ["Merriweather"].join(","),
        },
    });

    return (
        <div className="main-div">
            <img className="main-logo" src={require('./logo-HOS.png')}/>
            <ThemeProvider theme={theme}>
                <Box fontFamily={"serif"}>
                    <Tabs value={mode} onChange={handleMode}>
                        <Tab label="Tygodniowe słodkości" value={"casual"} disabled />
                        <Tab label="Zamówienia świąteczne" value={"event"} />
                    </Tabs>
                </Box>
            </ThemeProvider>
            <h1 className="title-header">
                {
                    mode == "casual"
                    ?
                    `House of Seitan Wajdeloty - rezerwacje tygodniowe słodkości`
                    :
                    `House of Seitan Wajdeloty - zamówienia świąteczne na Wielkanoc`
                }
            </h1>
            <br/>
            <br/>
            <br/>
            <h2 className="title-header">
                Zamówienia tylko z odbiorem własnym na Wajdeloty 3
            </h2>
            <h2 className="title-header">
                {
                    mode == "casual"
                        ?
                        `Odbiór zamówień od 13:00 do 18:30`
                        :
                        `Odbiór zamówień 30.03 od 11.00 do 17.00`
                }
            </h2>
            <br/>
            <br/>
            <br/>
            <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 4, sm: 6, md: 12}}>
                {dishes.map((dish: any, index: number) => (
                    <Grid key={dish.name} xs={2} item>
                        <tr>
                            <td height="50" className="border px-4 py-2 border-gray-600">{dish.name}</td>
                        </tr>
                        <tr>
                            <td className="border px-4 py-2 border-gray-600">
                                <img src={dish.image} title={dish.description} width="200" height="200"/>
                            </td>
                        </tr>
                        <tr>
                            <td className="border px-4 py-2 border-gray-600">{dish.description}</td>
                        </tr>
                        <tr>
                            <td className="border px-4 py-2 border-gray-600">{dish.price} PLN</td>
                        </tr>
                        <tr key={dish.id}>
                            <td className="incrementButton">
                                <button onClick={() => handleIncrementClick(index, 1, dish.id)}>+</button>
                            </td>
                            <td className="incrementButton">
                                <button onClick={() => handleIncrementClick(index, -1, dish.id)}>-</button>
                            </td>
                            <label>
                                {countArray[index]}
                            </label>
                        </tr>
                    </Grid>
                ))}
            </Grid>

            {/*<Table/>*/}
            {
                window.innerWidth > 500
                    ?
                    // <BigWindowTable
                    //     comment={comment}
                    //     handleComment={handleComment}
                    //     firstName={firstName}
                    //     handleFirstName={handleFirstName}
                    //     lastName={lastName}
                    //     handleLastName={handleLastName}
                    //     phone={phone}
                    //     handlePhone={handlePhone}
                    //     email={email}
                    //     handleEmail={handleEmail}
                    //     mode={mode}
                    //     collectDate={collectDate}
                    //     handleCollectDate={handleCollectDate}
                    // />
                    <table style={{marginTop: 20, paddingTop: 20}}>
                        <col className="column"/>
                        <col className="column"/>
                        <col className="column"/>
                        <col className="column"/>
                        <tr>
                            <td>
                                <BigTextField
                                    id={"order-comment"}
                                    label={"Komentarz"}
                                    rows={4}
                                    value={comment}
                                    onChange={handleComment}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <SmallTextField
                                    id={"order-first-name"}
                                    label={"Imię"}
                                    value={firstName}
                                    onChange={handleFirstName}
                                />
                            </td>
                            <td>
                                <SmallTextField
                                    id={"order-last-name"}
                                    label={"Nazwisko"}
                                    value={lastName}
                                    onChange={handleLastName}
                                />
                            </td>
                            <td>
                                <SmallTextField
                                    id={"order-phone"}
                                    label={"Nr telefonu"}
                                    value={phone}
                                    onChange={handlePhone}
                                />
                            </td>
                            <td>
                                <SmallTextField
                                    id={"order-email"}
                                    label={"Email"}
                                    value={email}
                                    onChange={handleEmail}
                                />
                            </td>
                            {
                                mode == "casual" &&
                                <td>
                                    <tr>Data odbioru</tr>
                                    <DatePicker
                                        dateFormat="dd-MM-yyyy"
                                        locale={"pl"}
                                        selected={collectDate}
                                        onChange={(date: Date) => setCollectDate(date)}
                                    />
                                </td>
                            }
                        </tr>
                    </table>
                    :
                    <table style={{marginTop: 20, paddingTop: 20}}>
                        <col className="column"/>
                        <col className="column"/>
                        <col className="column"/>
                        <col className="column"/>
                        <tr>
                            <td>
                                <BigTextField
                                    id={"order-comment"}
                                    label={"Komentarz"}
                                    rows={4}
                                    value={comment}
                                    onChange={handleComment}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <SmallTextField
                                    id={"order-first-name"}
                                    label={"Imię"}
                                    value={firstName}
                                    onChange={handleFirstName}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <SmallTextField
                                    id={"order-last-name"}
                                    label={"Nazwisko"}
                                    value={lastName}
                                    onChange={handleLastName}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <SmallTextField
                                    id={"order-phone"}
                                    label={"Nr telefonu"}
                                    value={phone}
                                    onChange={handlePhone}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <SmallTextField
                                    id={"order-email"}
                                    label={"Email"}
                                    value={email}
                                    onChange={handleEmail}
                                />
                            </td>
                            {
                                mode == "casual" &&
                                <td>
                                    <DatePicker
                                        dateFormat="dd-MM-yyyy"
                                        locale={"pl"}
                                        selected={collectDate}
                                        onChange={(date: Date) => setCollectDate(date)}
                                    />
                                </td>
                            }
                        </tr>
                    </table>
            }
            <table>
                <tr>
                    <label>
                        <input className="consent-input"
                               type="checkbox"
                               checked={consent1}
                               onChange={handleConsent1}
                        />
                        * Wyrażam zgodę na przetwarzanie moich danych osobowych przez House of Seitan sp. z.o.o.
                    </label>
                </tr>
                <tr>
                    <label>
                        <input className="consent-input"
                               type="checkbox"
                               checked={consent2}
                               onChange={handleConsent2}
                        />
                        * Wyrażam zgodę na kontakt telefoniczny i/lub mailowy w sprawie statusu zamówienia.
                    </label>
                </tr>
                <tr>
                    <label>
                        <input className="consent-input"
                               type="checkbox"
                               checked={consent3}
                               onChange={handleConsent3}
                        />
                        Wyrażam zgodę na kontakt telefoniczny i/lub mailowy w celach marketingowych.
                    </label>
                </tr>
            </table>
            <tr>
                <td>
                    <button
                        type="button"
                        className="bg-blue-500 hover:bg-blue-700 text-gray-800 font-bold m-1 py-2 px-4 rounded h-10 mr-3"
                        onClick={openModal}
                        style={{
                            borderRadius: 32,
                            fontFamily: "Merriweather",
                            fontSize: 16,
                            margin: 10,
                            padding: 20
                        }}
                    >
                        Złoż zamówienie
                    </button>
                </td>
                <td>
                    <button
                        type="button"
                        className="bg-blue-500 hover:bg-blue-700 text-gray-800 font-bold m-1 py-2 px-4 rounded h-10 mr-3"
                        onClick={handleClear}
                        style={{
                            borderRadius: 32,
                            fontFamily: "Merriweather",
                            fontSize: 16,
                            margin: 10,
                            padding: 20
                        }}
                    >
                        Wyczyść formularz
                    </button>
                </td>
            </tr>

            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <h2>Potwierdzenie zamówienia</h2>
                <div>
                    Podsumowanie:
                    {dishes.map((dish: any, index: number) => (
                        (countArray[index] !== 0)
                        &&
                        <tr>
                            <td>{dish.name}</td>
                            <td style={{textAlign: "right"}}>{countArray[index]} szt.</td>
                        </tr>
                    ))}
                    <tr>
                        <td>SUMA</td>
                        <td>{sum} PLN</td>
                    </tr>
                </div>
                <form>
                    <button
                        onClick={createOrder}
                        style={{
                            borderRadius: 32,
                            fontFamily: "Merriweather",
                            fontSize: 16,
                            margin: 10,
                            padding: 20
                        }}
                    >
                        Potwierdź
                    </button>
                    <button
                        onClick={closeModal}
                        style={{
                            borderRadius: 32,
                            fontFamily: "Merriweather",
                            fontSize: 16,
                            margin: 10,
                            padding: 20
                        }}
                    >
                        Anuluj zamówienie
                    </button>
                </form>
            </Modal>
            <Footer/>

            <ToastContainer/>
        </div>
    )
}

export default App
