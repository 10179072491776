import React from "react";
import { TextField } from "@mui/material";

function BigTextField(props) {
    // used for big, optional input like comment etc.
    // props:
    //     id: str
    //     label: str
    //     rows: int
    //     value: var
    //     onChange: function
    // TODO: props to interface
    return (
        <TextField
            id={props.id}
            label={props.label}
            multiline
            rows={props.rows}
            value={props.value}
            onChange={props.onChange}
            inputProps={{
                style: {
                    fontFamily: "Merriweather"
                }
            }}
            sx={{
                background: "white"
            }}
        />
    )
}

export default BigTextField
