import React from "react";
import {TextField} from "@mui/material";

function SmallTextField(props) {
    // used for small, required input like names, phone, email etc.
    // props:
    //     id: str
    //     label: str
    //     value: var
    //     onChange: function
    // TODO: props to interface
    return (
        <TextField
            required
            id={props.id}
            label={props.label}
            value={props.value}
            onChange={props.onChange}
            inputProps={{
                style: {
                    fontFamily: "Merriweather"
                }
            }}
            sx={{
                background: "white"
            }}
        />
    )
}

export default SmallTextField
