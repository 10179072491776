// src/componetns/Footer.tsx

import React, { FC, ReactElement } from "react";
import { Box, Container, Grid, Typography, createTheme, ThemeProvider } from "@mui/material";
import { SocialIcon } from 'react-social-icons'

export const Footer: FC = (): ReactElement => {
    const theme = createTheme({
        typography: {
            fontFamily: ["Merriweather"].join(","),
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Typography>
                <Box
                    sx={{
                        width: "100%",
                        height: "200px",
                        backgroundColor: "#e83c8c",
                        paddingTop: "1rem",
                        paddingBottom: "1rem",
                    }}
                >
                    <Container maxWidth="100%">
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <div>
                                    <img className="main-logo" src={require('./logo-HOS.png')}/>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography textAlign={"center"} color="textSecondary" variant="subtitle1">
                                    {`${new Date().getFullYear()} | House of Seitan sp. z o.o.`}
                                    <br/>
                                    {`80-437 Gdańsk, Wajdeloty 3`}
                                    <br/>
                                    {`NIP: 9571108149`}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography color="black" variant="h5">
                                    {/*https:// (or http://) need to make url external*/}
                                    <SocialIcon url="https://facebook.com/pyszniutkie" />
                                    <SocialIcon url="https://instagram.com/houseofseitan" />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Typography>
        </ThemeProvider>
    );
};

export default Footer;
